<template>
    <div>
        <div>
            <ul class="layui-nav">
                <li class="layui-nav-item layui-this"><a href="/addVerifications">验证列表</a></li>
            </ul>
        </div>

        <div>
            <table class="layui-hide" id="test" lay-filter="test"></table>
<!--            <script type="text/html" id="toolbarDemo">-->
<!--                <div class="layui-btn-container">-->
<!--                    <button class="layui-btn layui-btn-sm" lay-event="add" style="background-color: #375691;">-->
<!--                        新增-->
<!--                    </button>-->
<!--                </div>-->
<!--            </script>-->
<!--            <script type="text/html" id="barDemo">-->
<!--                <a class="layui-btn layui-btn-xs" lay-event="upd" style="background-color: #375691;">修改</a>-->
<!--                <a class="layui-btn layui-btn-xs" lay-event="add" style="background-color: #375691;">添加</a>-->
<!--                <a class="layui-btn layui-btn-xs" lay-event="del" style="background-color: #375691;">删除</a>-->
<!--            </script>-->
        </div>
        <div class="layui-row" id="addResources" style="display:none;">
            <form class="layui-form" lay-filter="example" style="padding-top: 1%;width: 96%;">
                <input type="hidden" name="id">
                <input type="hidden" name="alreadyVerifyNum">
                <div class="layui-form-item">
                    <label class="layui-form-label">UID</label>
                    <div class="layui-input-block">
                        <input type="text" name="uid" autocomplete="off" placeholder="唯一编码" class="layui-input">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">作者</label>
                    <div class="layui-input-block">
                        <input type="text" name="author" autocomplete="off" placeholder="作者" class="layui-input">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">总验证次数</label>
                    <div class="layui-input-block">
                        <input type="text" name="totalVerifyNum" autocomplete="off" placeholder="总验证次数" class="layui-input">
                    </div>
                </div>

                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit="" lay-filter="demo2"
                                style="margin-top: 30px;margin-left: 35%;background-color: #375691;">立即提交
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <div class="layui-row" id="addResources2" style="display:none;">
            <div id="view"></div>
            <form class="layui-form" lay-filter="example2" style="padding-top: 1%;width: 96%;">
                <div style="background-image: url(../assets/images/1_03.jpg);width: 1068px;height: 623px;margin:80px auto;">
                    <div style="padding: 50px;line-height: 22px;">
                        <p>
                            <i class="layui-icon layui-icon-right" style="color: black;"></i>
                            <i class="layui-icon layui-icon-right" style="color: #858181;;"></i>
                            <i class="layui-icon layui-icon-right" style="color: #e5e5e5;"></i>
							<!-- data-id={{item.id}} -->
                            <i class="layui-icon layui-icon-delete" style="color: red;font-size: 30px;cursor: pointer;"
                               
							   ></i>
                        </p>
                        <div style="padding-top: 10px;">
                            <p style="font-style: italic;">
                                <input type="text" name="fieldA"  autocomplete="off"  class="layui-input">
                                <input type="hidden" name="uid"  class="layui-input">
                            </p>
                        </div>
                        <div style="padding-top: 20px;">
                            <div class="layui-panel"
                                 style="height: 50px;border-radius: 50px;width: 400px;margin: 40px auto;background-color: #EAF6FA;box-shadow: 0px -1px 5px 1px #b1e2ef;">
                                <div style="line-height: 50px;text-align: center;">
                                    <p style="font-size: 20px;">
                                        <span style="color: #219eae;font-weight: bold;;">CF-1=<input type="text" name="fieldB"  autocomplete="off"  class="layui-input" style="display: inherit;width: 50px"></span>
                                        <span style="margin-left: 50px;">(JIF<span
                                                style="font-size: 12px;"><input type="text" name="fieldC"  autocomplete="off"  class="layui-input" style="display: inherit;width: 50px"></span>=<input type="text" name="fieldD"  autocomplete="off"  class="layui-input" style="display: inherit;width: 50px">)</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="padding-top: 20px;">
                            <p style="color: #b7292e;">
                                <input type="text" name="fieldE"  autocomplete="off"  class="layui-input" >
                            </p>
                        </div>
                        <div style="padding-top: 20px;">
                            <div class="layui-panel"
                                 style="height: 50px;border-radius: 50px;width: 400px;margin: 40px auto;background-color: #EAF6FA;box-shadow: 0px -1px 5px 1px #b1e2ef;">
                                <div style="line-height: 50px;text-align: center;">
                                    <p style="font-size: 20px;">
                                        <span style="color: #219eae;font-weight: bold;">CF-2=<input type="text" name="fieldF"  autocomplete="off"  class="layui-input" style="display: inherit;width: 50px"></span>
                                        <span style="margin-left: 50px;">(JIF<span
                                                style="font-size: 12px;"><input type="text" name="fieldG"  autocomplete="off"  class="layui-input" style="display: inherit;width: 50px"></span>
										=<input type="text" name="fieldH"  autocomplete="off"  class="layui-input" style="display: inherit;width: 50px">)</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style="padding-top: 20px;">
                            <p style="color: #b7292e;">
                                <input type="text" name="fieldI"  autocomplete="off"  class="layui-input" >
                            </p>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit="" lay-filter="demo3"
                                    style="margin-top: 30px;margin-left: 35%;background-color: #375691;">立即提交
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    </div>
</template>

<script>
    export default {
        name: "addVerify"
    }
</script>

<style scoped>

</style>